exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* #855CD6 */ /* 35% transparent version of looks-tertiary */ /* 15% transparent version of looks-tertiary */ /* #714EB6 */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #0B8E69 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .progress-ring_progress-ring_P_His {\n    margin: 0.5rem;\n} .progress-ring_progress-ring-svg_17Srp {\n    fill: transparent;\n} .progress-ring_progress-ring_P_His .progress-ring_progress-ring-ring_2NyUq {\n    stroke: hsla(260, 60%, 60%, 0.15);\n} .progress-ring_progress-ring_P_His .progress-ring_progress-ring-value_1SVN5 {\n    stroke: rgb(93, 143, 113);\n    stroke-linecap: round;\n}\n", ""]);

// exports
exports.locals = {
	"progress-ring": "progress-ring_progress-ring_P_His",
	"progressRing": "progress-ring_progress-ring_P_His",
	"progress-ring-svg": "progress-ring_progress-ring-svg_17Srp",
	"progressRingSvg": "progress-ring_progress-ring-svg_17Srp",
	"progress-ring-ring": "progress-ring_progress-ring-ring_2NyUq",
	"progressRingRing": "progress-ring_progress-ring-ring_2NyUq",
	"progress-ring-value": "progress-ring_progress-ring-value_1SVN5",
	"progressRingValue": "progress-ring_progress-ring-value_1SVN5"
};